'use strict';
/* global $ */

/* 舊IE提醒 */
var userAgent = window.navigator.userAgent;
if (
	userAgent.indexOf('MSIE 7.0') > 0 ||
	userAgent.indexOf('MSIE 8.0') > 0 ||
	userAgent.indexOf('MSIE 9.0') > 0 ||
	userAgent.indexOf('MSIE 10.0') > 0
) {
	location.href = 'browser.html';
}

(function () {
	/* ----------------------------------- [START] Loader 移除 */
	var loaderRemove = function () {
		var loader = document.querySelector('#loader');

		loader.classList.add('loaded');
		setTimeout(function () {
			loader.remove();
		}, 2000);
	};
	window.addEventListener('load', loaderRemove);
	/* ----------------------------------- [END] Loader 移除 */

	/* ---------------------------------------- [START] Lazyload */
	/*
	 * 使用：https://github.com/tuupola/lazyload
	 * 尋找頁面上的 .lazyload 為執行 lazy load 物件
	 */
	function buildLazyLoad() {
		lazyload();
	}
	window.addEventListener('load', buildLazyLoad);
	/* ---------------------------------------- [END] Lazyload */

	/* ---------------------------------------- [START] ScrollTriggerAni */
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	// 用套件也好，換其它習慣方式也罷，請達成↑目的
	let aniSec = document.querySelectorAll('.js-ani');
	const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用

	function scrollTriggerAni() {
		for (var i = 0; i < aniSec.length; i++) {
			if (isInViewport(aniSec[i])) {
				aniSec[i].classList.add('is-animated');
			}
		}

		cleanTrigger();

		// If load all of the item, stop Listener
		if (aniSec.length === 0) {
			window.removeEventListener('scroll', scrollTriggerAniThrottle);
		}
	}

	function cleanTrigger() {
		aniSec = Array.prototype.filter.call(aniSec, function (ani) {
			return !ani.classList.contains('is-animated');
		});
	}

	window.addEventListener('load', function() {
		setTimeout(function() {
			scrollTriggerAni();
			window.addEventListener('scroll', scrollTriggerAniThrottle);
		}, 100)
	});

	/* Tool ----------------------------------------- */
	function isInViewport(el) {
		const rect = el.getBoundingClientRect();
		const isVisible = el.offsetHeight !== 0;

		return (
			isVisible &&
			rect.bottom >= 0 &&
			rect.right >= 0 &&
			rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.left <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}
	// https://www.cnblogs.com/coco1s/p/5499469.html
	// 簡單的節流函數
	function throttle(func, wait, mustRun) {
		var timeout;
		var startTime = new Date();

		return function () {
			var context = this;
			var args = arguments;
			var curTime = new Date();

			clearTimeout(timeout);
			// 如果達到了規定的觸發時間間隔，觸發 handler
			if (curTime - startTime >= mustRun) {
				func.apply(context, args);
				startTime = curTime;
				// 沒達到觸發間隔，重新設定定時器
			} else {
				timeout = setTimeout(func, wait);
			}
		};
	}
	/* ---------------------------------------- [END] ScrollTriggerAni */

	// ----------------------------------- [START] Ease scroll
	var buildEaseScroll = function() {
		if (window.EaseScroll === undefined) return false;
		new EaseScroll({
			frameRate: 60,
			animationTime: 1000,
			stepSize: 100,
			pulseAlgorithm: 1,
			pulseScale: 6,
			pulseNormalize: 1,
			accelerationDelta: 20,
			accelerationMax: 1,
			keyboardSupport: true,
			arrowScroll: 30,
			touchpadSupport: true,
			fixedBackground: true,

			// Browser Setting Control
			browser: {
				Chrome: true,
				FireFox: false,
				Safari: true,
				IE: true,
				Edge: true
			}
		});
	}
	window.addEventListener('load', buildEaseScroll);
	// ----------------------------------- [END] Ease scroll

})();
